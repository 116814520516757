import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFoundComponent } from "./shared/components/not-found/not-found.component";
import { IsLoggedGuard } from "./shared/guards/is-logged.guard";
import { IsAuthGuard } from "./shared/guards/is-auth.guard";
import { ExternalMealLogFormComponent } from "./external-form/external-meal-log-form/external-meal-log-form.component";
import { ExternalSurveyFormComponent } from "./external-form/external-survey-form/external-survey-form.component";

const routes: Routes = [
  { path: "", loadChildren: () => import("./home/home.module").then((m) => m.HomeModule) },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
    canActivate: [IsAuthGuard],
  },
  {
    path: "user",
    loadChildren: () => import("./user/user.module").then((m) => m.UserModule),
    canActivate: [IsLoggedGuard],
  },
  {
    path: "client",
    loadChildren: () => import("./client/client.module").then((m) => m.ClientModule),
    canActivate: [IsLoggedGuard],
  },
  {
    path: "meal-log/:userCode",
    component: ExternalMealLogFormComponent,
  },
  {
    path: "survey/:userCode",
    component: ExternalSurveyFormComponent,
  },
  { path: "**", component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
